import ApiConfig from './ApiConfig'
import {
  ExternalClient_V1,
} from './generated/api.generated.v1'

import variables from '@/variables'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    runPatientImport() {
      const api = new ExternalClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .runImportService()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    runPharmacyImport() {
      const api = new ExternalClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .pollSFTP()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
