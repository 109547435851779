<template>
  <div>
    <div>
      WIP Work In Progress
    </div>
    <div>
      <v-btn
        color="warning"
        @click="processPatientImport"
        :disabled="userRoles != 'Global'"
      >
        Process Patient Import(Do Not Touch)
      </v-btn>
      <v-btn
        color="warning"
        @click="processPharmacyImport"
        :disabled="userRoles != 'Global'"
      >
        Process Pharmacy Import(Do Not Touch)
      </v-btn>
    </div>
  </div>
</template>
<script>
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import useExternalList from '@/store/useExternalService'
import ExternalService from '@/services/ExternalService'
import { SimpleCrypto } from 'simple-crypto-js'

export default {
  setup() {
    const EXTERNAL_APP = 'app-external'

    // Register module
    if (!store.hasModule(EXTERNAL_APP)) {
      store.registerModule(EXTERNAL_APP, ExternalService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EXTERNAL_APP)) store.unregisterModule(EXTERNAL_APP)
    })

    const { fetchPatientImport, fetchPharmacyImport } = useExternalList()

    const CryptoSecretKey = 'DetainEHRSecretKeyThatWillAllowUsToBeSuperSecure'
    const SCrypt = new SimpleCrypto(CryptoSecretKey)
    const encryptedUserRoles = localStorage.getItem('encryptedRolePackage')
    const userRoles = SCrypt.decrypt(encryptedUserRoles)

    return {
      fetchPatientImport,
      fetchPharmacyImport,

      userRoles,
    }
  },
  methods: {
    processPatientImport() {
      if (this.userRoles == 'Global'){
        this.fetchPatientImport()
      }
      else{
        alert("Contact Support")
      }

    },
    processPharmacyImport() {
      if (this.userRoles == 'Global'){
        this.fetchPharmacyImport()
      }
      else{
        alert("Contact Support")
      }

    },
  },
}
</script>


