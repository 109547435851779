import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useExternalList() {
  const loading = ref(false)
  const sitePatientList = ref([])
  const siteMedList = ref([])

  // get patient imports
  const fetchPharmacyImport = () => {
    loading.value = true
    store
      .dispatch('app-external/runPharmacyImport')
      .then(response => {
        sitePatientList.value = response

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    fetchPharmacyImport()
  })

  // get patient imports
  const fetchPatientImport = () => {
    loading.value = true
    store
      .dispatch('app-external/runPatientImport')
      .then(response => {
        siteMedList.value = response

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    fetchPatientImport()
  })

  return {
    loading,

    siteMedList,
    sitePatientList,
    fetchPharmacyImport,
    fetchPatientImport,
  }
}
